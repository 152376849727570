import React, {Component} from 'react'
import './App.css'
import VideoItem from './components/video-item'
import VideoModal from './components/modal/video-modal'


class App extends Component {

    constructor(props) {
        super(props)
        this.videos = [
            {
                link: "https://apps.apple.com/us/app/groove-maps/id1165006582",
                title: "Groove Maps",
                thumb: "groovemapsx266.jpg",
                description: "",
                text: <div>
                    Technology: iOS Swift/React Native<br/>
                    Client: Groove Maps, Inc.<br/>
                    Role: Lead Mobile Developer<br/>
                </div>
            },
            {
                code: "244965356",
                title: "McDonald's iOS App",
                thumb: "664399822_472x266.jpg",
                description: "",
                text: <div>
                    Technology: iOS Swift/Objective-C<br/>
                    Agency: Method Inc.<br/>
                    Client: McDonald's Corporation<br/>
                    Role: Senior iOS Developer<br/>
                </div>

            },
            {
                code: "240960829",
                title: "Tagiio iOS App",
                thumb: "664403175_472x266.jpg",
                description: "",
                text: <div>
                    Technology: iOS Objective-C<br/>
                    Client: Out of the Box Software Inc.<br/>
                    Role: iOS Lead Developer and Tech Lead<br/>
                </div>

            },
            {
                code: "136185366",
                title: "SiriusXM App",
                thumb: "530590870_472x266.jpg",
                description: "",
                text: <div>
                    Technology: iOS Objective-C<br/>
                    Agency: Code and Theory<br/>
                    Client: SiriusXM<br/>
                    Role: Lead iOS Developer<br/>
                </div>
            },
            {
                code: "37091148",
                title: "Ogilvy Interactive Demo Reel",
                description: "Featured in this video are a number of projects I built while at Ogilvy Interactive. My work here includes Ogilvy.com (00:55), TwittEarth (01:08), Mylecule (01:50), and I am also on camera showing off an Augmented Reality demo that reveals a 3D rotating globe (01:15). Other campaigns featured in this video that I was involved in include Kodak and Time Warner Cable.",
                thumb: "255117310_472x266.jpg",
                text: <div>
                    Video Editing: Greg Muth<br/>
                    Agency: Ogilvy Interactive<br/>
                    Client: Internal Showcase
                </div>
            },
            {
                code: "37156831",
                title: "Droid Does",
                thumb: "255121874_472x266.jpg",
                description: "Originally hosted on www.droiddoes.com",
                text: <div>
                    Technology: Away3D/Pixelbender, Robotlegs<br/>
                    Agency: Mcgarrybowen<br/>
                    Client: Verizon<br/>
                    Role: Co-Developer/Away3D Lead<br/>
                </div>

            },
            {
                code: "37094613",
                title: "Samsung 3D TV",
                thumb: "255117736_472x266.jpg",
                description: "",
                text: <div>
                    Technology: Papervision 3D<br/>
                    Agency: Digitas<br/>
                    Client: Samsung<br/>
                    Role: Lead Developer/Architect
                </div>
            },
            {
                code: "38349067",
                title: "Nikon 1",
                thumb: "263886542_472x266.jpg",
                description: "Originally hosted at one.nikonusa.com",
                text: <div>
                    Technology: Away 3D, Robotlegs framework<br/>
                    Agency: MRM Worldwide<br/>
                    Client: Nikon<br/>
                    Role: Co-Developer<br/>
                </div>
            },
            {
                code: "37157934",
                title: "IBM Lotus Knows",
                thumb: "255133979_472x266.jpg",
                description: "Winner of the BMA 2010 ACE Award. This project uses the Planet Explorer 3D API which I authored and is a utility that aides in the plotting of objects onto a sphere using latitudes and longitudes. In this project user tweets are posted in real time to the globe.",
                text: <div>
                    Technology: Papervision 3D / Twitter API<br/>
                    Agency: Ogilvy Interactive<br/>
                    Client: IBM<br/>
                    Role: Lead Developer/Architect<br/>
                </div>
            },
            {
                code: "37996285",
                title: "NYT: A Century in Times Square",
                thumb: "261218664_472x266.jpg",
                description: "A timeline of the history of Times Square on a 42\" touch screen. Used during New York Fashion Week and permanently located in the Starbucks flagship store and in the Times Square Visitor Center.",
                text: <div>
                    Client: The New York Times<br/>
                    Technology: AS3<br/>
                    Role: Lead Developer<br/>
                </div>
            },
            {
                code: "37162743",
                title: "Shoot My Truck",
                thumb: "255166686_472x266.jpg",
                description: <div>A small taste of the two day live event that started on 2/14/12 where users could log
                    on to the site at www.shootmytruck.com and fire a fully automatic machine gun using their
                    keyboard.<br/><br/>View the prelaunch trailer
                    at <a
                        href="https://www.youtube.com/watch?v=Hn43glnOJVo">https://www.youtube.com/watch?v=Hn43glnOJVo.</a>
                </div>,
                text: <div>
                    Technology: Flash/JavaScript front-end with a Node JS server, 4 live video streams, and
                    Arduino controlled weapons<br/>
                    Client: Sony<br/>
                    Agency: Stink Digital<br/>
                    Role: AS3 Co-Developer<br/>
                </div>
            },
            {
                code: "38574263",
                title: "Verizon FiOS Digital Welcome Kit",
                thumb: "265557792_472x266.jpg",
                description: "",
                text: <div>
                    Agency: MRM Worldwide<br/>
                    Client: Verizon<br/>
                    Role: Lead Developer<br/>
                </div>
            },
            {
                code: "46292214",
                title: "Volvo Microsite",
                thumb: "322286334_472x266.jpg",
                description: "",
                text: <div>
                    Technology: HTML5/CSS3/JavaScript<br/>
                    Agency: Euro RSCG<br/>
                    Client: Volvo<br/>
                    Role: Lead Front-End Developer<br/>
                </div>
            },
            {
                code: "136184121",
                title: "Chipotle Order App",
                thumb: "530588626_472x266.jpg",
                description: "",
                text: <div>
                    Technology: iOS Objective-C<br/>
                    Agency: Code and Theory<br/>
                    Client: Chipotle<br/>
                    Role: Lead iOS Developer<br/>
                </div>
            },
            {
                code: "46272931",
                title: "Volvo iPad web app",
                thumb: "326243041_472x266.jpg",
                description: "",
                text: <div>
                    Technology: HTML5/CSS3/JavaScript
                    Agency: Euro RSCG
                    Client: Volvo
                    Music: when by Stephan Siebert (http://www.myspace.com/stephansiebert)
                    Role: Lead Front-End Developer
                </div>
            }
        ]
        this.state = {
            selected: null,
            errors: null
        }
    }

    selectItem = (data) => {
        console.log(data)
        this.setState({selected: data})
    }

    createGrid = () => {
        let items = []
        for (let i = 0; i < this.videos.length; i++) {
            if (this.videos[i].code) {
                items.push(<VideoItem text={this.videos[i].text}
                                      image={process.env.PUBLIC_URL + '/images/' + this.videos[i].thumb}
                                      title={this.videos[i].title}
                                      description={this.videos[i].text}
                                      key={i}
                                      onClick={() => {
                                          this.selectItem(this.videos[i])
                                      }}
                />)
            } else {
                items.push(<VideoItem text={this.videos[i].text}
                                      link={this.videos[i].link}
                                      image={process.env.PUBLIC_URL + '/images/' + this.videos[i].thumb}
                                      title={this.videos[i].title}
                                      description={this.videos[i].text}
                                      key={i}
                />)
            }
        }


        return items
    }

    closeModal = () => {
        this.setState({selected: null})
    }

    render() {
        return <div className="App">
            <header className="App-header">
                <p className="maintitle">
                    Patrick Wilson.
                </p>
                <div className="App-grid-container">
                    {this.createGrid()}
                </div>
            </header>

            {this.state.selected && <VideoModal
                data={this.state.selected}
                onClose={() => {
                    this.closeModal()
                }}/>}

        </div>
    }

}

export default App
