import React, {Component} from 'react'
import './styles.css'
import Vimeo from '@u-wave/react-vimeo'


class VideoModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            videoIsReady: false
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.keyPressed, false)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyPressed, false)
    }

    keyPressed = (e) => {
        if (e.keyCode === 27) {
            this.props.onClose()
        }
    }

    onVideoReady = () => {
        this.setState({videoIsReady: true})
    }

    onVideoError = () => {
        this.setState({videoIsReady: true})
        console.log("Vimeo Error")
    }

    onClickOutside = (e) => {
        if (e) {
            e.preventDefault()
        }
        console.log(e.target.className)
        let cName = e.target.className
        if (cName === "modal-content" || cName === "modal-container") {
            this.props.onClose()
        }
    }

    render() {
        let code = this.props.data.code
        let title = this.props.data.title
        let description = this.props.data.description
        let credits = this.props.data.text
        let bottomText = ""

        if (this.state.videoIsReady) {
            bottomText = <div>
                <div className={"modal-title"}>{title}</div>
                <div className={"modal-description"}>{description}</div>
                <div className={"modal-credits"}>{credits}</div>
            </div>
        }

        return (
            <div className={"modal-container"} onMouseDown={this.onClickOutside}>
                <div className={"modal-content"}>
                    <div className={"modal-block"}>
                        <div className={"modal-close"}>
                            <button className={"modal-close-btn"} onMouseDown={() => {
                                this.props.onClose()
                            }}>X
                            </button>
                        </div>
                        <Vimeo
                            video={code}
                            showByline={false}
                            showPortrait={false}
                            showTitle={false}
                            className={"modal-player"}
                            color={"#cccccc"}
                            onReady={this.onVideoReady}
                            onError={this.onVideoError}
                            muted={true}
                            responsive={true}
                            autoplay
                        />
                        {bottomText}
                    </div>
                </div>

            </div>
        )
    }
}

export default VideoModal


/*

<p className="first">Featured in this video are a number of projects I built while at Ogilvy Interactive. My work here includes Ogilvy.com (<a href="javascript:void(0)" className="video_time" data-time="55">00:55</a>), TwittEarth (<a href="javascript:void(0)" className="video_time" data-time="68">01:08</a>), Mylecule (<a href="javascript:void(0)" className="video_time" data-time="110">01:50</a>), and I am also on camera showing off an Augmented Reality demo that reveals a 3D rotating globe (<a href="javascript:void(0)" className="video_time" data-time="75">01:15</a>). Other campaigns featured in this video that I was involved in include Kodak and Time Warner Cable.</p>

 */
