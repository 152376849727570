import React, {Component} from 'react'


class VideoItem extends Component {
    constructor(props) {
        super(props)
        this.isTouchDevice = typeof window.ontouchstart !== 'undefined'
        this.state = {
            isHovered: false
        }
    }

    componentWillMount() {
        //
    }

    componentDidMount() {
        //
    }

    onClick = () => {
        this.props.onClick()
    }

    onHover = () => {
        if (!this.isTouchDevice) {
            this.setState({isHovered: true})
        }
    }

    onHoverOut = () => {
        this.setState({isHovered: false})
    }


    render() {
        let hovered = ""
        let hoverImage = ""
        if (this.props.link) {
            hoverImage = <div><a
                href={this.props.link}
                target="_blank"><img src={this.props.image}
                                     className='App-thumb-image'
                                     alt={this.props.title}
                                     onMouseOver={this.onHover}
                                     onMouseLeave={this.onHoverOut}/></a></div>
        } else {
            hoverImage = <img src={this.props.image} className='App-thumb-image' alt={this.props.title}
                              onMouseDown={this.onClick}
                              onMouseOver={this.onHover}
                              onMouseLeave={this.onHoverOut}/>
        }
        if (this.state.isHovered) {
            if (this.props.link) {
                hovered =
                    <img src={process.env.PUBLIC_URL + '/images/appstore-overlay.png'} className='App-thumb-image-over'
                         alt='hover'/>
            } else {
                hovered =
                    <img src={process.env.PUBLIC_URL + '/images/play-overlay.png'} className='App-thumb-image-over'
                         alt='hover'/>
            }

        }

        return <div className='App-thumb'>
            {hovered}
            {hoverImage}
            <code className='.App-thumb-text'>
                <b>{this.props.title}</b>
                {this.props.text}
            </code>
        </div>
    }
}

export default VideoItem
